import countries from '@/plugins/i18n/ressources/countries.json'
import phoneCountries from '@/helpers/country-list'

const findCountry = (iso2) => countries.find(c => c.alpha2 === iso2)

export function getCountryByIso2 (iso2, locale) {
	const country = findCountry(iso2)
	return country?.[locale] ?? null
}

const getCountryDetails = (country, locale) => ({
	name: getCountryByIso2(country[1], locale) ?? country[0],
	iso2: country[1].toUpperCase(),
	dialCode: country[2],
	priority: country[3] || 0,
	areaCodes: country[4] || null
})

export function getPhoneCountries (locale) {
	return phoneCountries.map(country => getCountryDetails(country, locale))
}
